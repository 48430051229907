@import url("https://fonts.googleapis.com/css2?family=Be+Vietnam+Pro:wght@300;400;500;700&family=DM+Mono:wght@400;500&display=swap");

body {
  width: 100%;
  min-height: 100vh;
  overflow-x: hidden;
  @apply bg-darkshade1;
}

body::-webkit-scrollbar {
  width: 8px;
  background: transparent;
}

body::-webkit-scrollbar-track {
  background: blue;
  display: none;
}

body::-webkit-scrollbar-thumb {
  background-color: #fff2;
  backdrop-filter: blur(5px);
  border-radius: 10px;
}

@keyframes scaleDownIn {
  from {
    opacity: 0;
    transform: scale(4) translateY(0px);
  }
  to {
    opacity: 1;
    transform: scale(1) translateY(0px);
  }
}

@keyframes float {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(5px);
  }
  100% {
    transform: translateY(0px);
  }
}

.heroSection {
  animation: scaleDownIn 1.5s cubic-bezier(0.87, 0, 0.13, 1);
}

.heroSection .sqD:not(.squiggle-hero-pop1) {
  animation: float 2s infinite ease-in-out;
}
.heroSubTitle .sqD {
  @apply scale-[0.8] lg:scale-100;
}

/* All svg squiggle decorations */
.sqD {
  @apply absolute z-[-10];
}

/* Mobile Nav */

.burger {
  transition: opacity 300ms ease;
  border: 0;
  background: transparent;
  width: 20px;
  height: 20px;
  position: relative;
}

.burger svg {
  transform: translate(-50%, -50%) scale(1);
  top: 50%;
  left: 50%;
  opacity: 1;
  transition: opacity 300ms ease, transform 300ms ease;
}

.burger svg[data-hide="true"] {
  opacity: 0;
  transform: translate(-50%, -50%) scale(0);
}

.menu {
  margin: 0;
  padding: 40px 20px 0;
  width: 100%;
  height: 100vh;
  z-index: 100;
  opacity: 0;
  left: 0;
  transition: opacity 300ms ease, transform 300ms ease;
}

.menu li {
  transform: translateX(-16px);
  opacity: 0;
  transition: opacity 300ms ease, transform 300ms ease, width 300ms ease,
    border-color 300ms ease;
  width: 0px;
  white-space: nowrap;
}

.menuRendered {
  opacity: 1;
}

.menuRendered li {
  @apply border-gray-600 w-full;
  transform: translateX(0);
  opacity: 1;
}

.menu > * + * {
  margin-top: 24px;
}

.landingSectionTitle::before {
  content: "";
  position: absolute;
  left: 105%;
  right: 0px;
  top: 50%;
  transform: translateY(-50%);
  height: 4px;
  width: 100vw;
  @apply bg-sky-500;
}

@media (max-width: 768px) {
  .landingSectionTitle::before {
    left: 50%;
    right: auto;
    top: -20px;
    transform: translate(-50%, -50%);
    height: 8px;
    width: 150px;
    @apply bg-sky-500;
  }
}
